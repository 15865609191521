// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here
$body-bg: #000;
$border-radius: 50%;
$dark-orange: #BD3E21;
$tooltip-border-radius: 0.25rem;
$input-border-radius: 0.25rem;
$card-border-radius: 0.25rem;

$blue:    #0d6efd;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #ff5129;
$yellow:  #ffc107;
$green:   #198754;
$teal:    #20c997;
$cyan:    #0dcaf0;

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$primary:       $orange;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;
$tertiary:      $gray-500;

$theme-colors: (
  primary:    $primary,
  secondary:  $secondary,
  success:    $success,
  info:       $info,
  warning:    $warning,
  danger:     $danger,
  light:      $light,
  dark:       $dark,
  tertiary:   $tertiary
);

$component-active-color: #fff;
$component-active-bg: $orange;

$nav-pills-border-radius:           $border-radius;
$nav-pills-link-active-color:       $component-active-color;
$nav-pills-link-active-bg:          $component-active-bg;

$nav-link-color: $orange;
$nav-link-hover-color: $dark-orange;
$nav-link-active-color: $dark-orange;

:root {
  --bs-btn-disabled-border-color: #00000066;
  --bs-btn-disabled-opacity: 0.45;
}

.btn:disabled {
  border-color: var(--bs-btn-disabled-border-color);
}


// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";

// 8. Add additional custom code here

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
